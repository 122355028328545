import { Divider, Paragraph } from '@stage-ui/core'
import ParagraphTypes from '@stage-ui/core/content/Paragraph/types'

export const LineTitle = (props: ParagraphTypes.Props & { isDark?: boolean }) => {
  const { children, isDark, ...restProps } = props
  return (
    <Paragraph
      color={isDark ? 'primary' : 'onSurface'}
      weight={700}
      position="relative"
      display="inline-block"
      transform="uppercase"
      {...restProps}
    >
      <Divider
        color={isDark ? 'primary' : 'onSurface'}
        w="100rem"
        t="0.7rem"
        r="calc(100% + 1rem)"
        position="absolute"
      />
      {children}
    </Paragraph>
  )
}
